/**
 * Created on : 2018
 * Author     : WebDesign
 *
 * font-family: 'Roboto Condensed', sans-serif;
 */

@font-face {
  font-family: Klavika-Bold;
  src: url('/fonts/Klavika-Bold.ttf');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  min-height: 588px;
  background: transparent;
}
#wrapper {
  min-height: 100%;
  /*height: 100%;*/
  position: relative;
}
#header {
  display: none;
  background: #ededed;
  padding: 10px;
}
#content {
  padding-bottom: 300px; /* Height of the footer element */
}
#footer {
  background: #333e48;
  width: 100%;
  height: 300px;
  position:absolute;
  bottom: 0;
  left: 0;
  margin: 0 !important;
}




/* === TPL CUSTOM === */
body, #wrapper {
  min-width: 320px;
}
#header {
  position: fixed;
  z-index: 1000;
  height: 80px;
  width: 100%;
}
#content {
  padding-top: 80px;
}

/* === LINKS === */
.areas .ft-sz-10{
  font-size: 16px;
}
a {
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
}


a, a:hover, a:visited, a:active, a:focus {
  text-decoration: none;
  outline: none;
  color: #333e48;
  cursor: pointer;
}

.inc_logo{
  height: 30px !important;
  width: 100px !important;
  margin-right: 50px !important;
  display: inline-block !important;
}

/* === FONTS === */
h1, h2, small {
  width: 100%;
  display: inline-block;
  text-align: center;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 30px;
}
h4 {
  font-size: 10px;
}
small, figcaption {
  font-size: 15px;
}

.welcome-header-img{
  margin-top:10px;
  margin-left:20px;
}

/* === FONT CUSTOM === */
.ft-sz-75{
  font-size:75px;
}
.ft-sz-60 {
  font-size: 60px;
}
.ft-sz-50 {
  font-size: 60px;
}
.ft-sz-40 {
  font-size: 40px;
}
.ft-sz-30 {
  font-size: 30px;
}
.ft-sz-20 {
  font-size: 20px;
}
.ft-sz-12 {
  font-size: 12px;
}
.ft-sz-15 {
  font-size: 15px;
}
.ft-sz-10 {
  font-size: 10px;
}
.ft-sz-9 {
  font-size: 9px;
}

/* === OTHER ===*/
.error {
  display: inline-block;
  padding: 100px 100px;
  width: 100%;
  text-align: center;
}
.clear{
  clear: both;
}

.pad-tb-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

/* === CONTENT === */


/* === BOOSTRAP CUSTOM === */
.row {
  padding: 0;
  margin: 0;
  /*margin-top: 20vh;*/
}

/*MODAL*/
#filter-modal .modal-header, .contact-form .modal-header {
  border-bottom: 0;
}

#filter-modal .modal-header, .contact-form .modal-header {
  border-bottom: 0;
  margin-bottom: 50px;
}

.modal, .modal-open .modal, .modal .modal-content, .modal .modal-content {
  background-color: #333e48;
}

.contact-form .modal-header > button.close, .contact-form .modal-dialog {
  margin-top: 0;
}
.modal {
  /*overflow-x: hidden !important;*/
  overflow: hidden !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 10000000 !important;
}
.modal-dialog {
  height: 100%;
}

.modal .modal-content {
  top: 50%;
  transform: translate3d(0, -50%, 0);
  border: 0;
}
.contact-form .modal-header > button.close {
  margin: 0 auto;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(44, 173, 160, 1);
  background-image: url('/img/frontend_assets/button_close.png');
}

.modal-footer {
  border: 0;
}
.jssocials-shares {
  text-align: center;
  display: none;
}
.product-detail .jssocials-shares
{
  display: block!important;
}
#share-icons .app-icon .fa-2x {
  font-size: 2em;
  line-height: 60px;
}
#share-icons .fab, #share-icons .fa-at {
  color: #ececee;
}
#share-icons .app-icon.icon-social:first-of-type {
  margin-right: 10px;
}
#share-icons .app-icon.icon-social {
  background-color: rgba(44, 173, 160, 0.8);
}
#share-icons .app-icon {
  display: inline-block;
}
.app-icon.icon-social {
  background: #373737;
}
.app-icon:hover .content, .app-icon:hover .fa-twitter, .app-icon.icon-social, .app-icon:hover a {
  color: #ececee;
}
.app-icon {
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: -webkit-transform .9s cubic-bezier(.4,0,0,1);
  transition: -webkit-transform .9s cubic-bezier(.4,0,0,1);
  transition: transform .9s cubic-bezier(.4,0,0,1);
  transition: transform .9s cubic-bezier(.4,0,0,1), -webkit-transform .9s cubic-bezier(.4,0,0,1);
}

button.jssocials-share-link.social_share{
  border: 0;
  background: transparent;
  outline: 0;
}

.app-icon:hover:not(.icon-lang) {
  transform: scale(1.1);
}

.img-fluid {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*@media screen and (max-width: 768px) {
     === FONTS ===
    .ft-sz-50 {
        font-size: 30px;
    }
    .ft-sz-30 {
        font-size: 18px;
    }
    .ft-sz-20 {
        font-size: 12px;
    }
    small, figcaption {
        font-size: 9px;
    }
}*/

/*@media screen and (max-width: 992px) {
     === FONTS ===
    .ft-sz-50 {
        font-size: 50px;
    }
    .ft-sz-30 {
        font-size: 30px;
    }
    .ft-sz-20 {
        font-size: 20px;
    }
    small, figcaption {
        font-size: 15px;
    }
}*/

@media screen and (min-width: 320px) {
  .ft-sz-75{
    font-size:45px;
  }
  .ft-sz-60 {
    font-size: 36px;
  }
  .ft-sz-50 {
    font-size: 30px;
  }
  .ft-sz-40 {
    font-size: 24px;
  }
  .ft-sz-30 {
    font-size: 18px;
  }
  .ft-sz-20 {
    font-size: 12px;
  }
  .ft-sz-15 {
    font-size: 9px;
  }
  .ft-sz-12 {
    font-size: 7px;
  }
  .ft-sz-10 {
    font-size: 6px;
  }
  .ft-sz-9 {
    font-size: 5px;
  }
}

@media screen and (min-width: 768px) {
  /* === FONTS === */
  .ft-sz-75{
    font-size:60px;
  }
  .ft-sz-60 {
    font-size: 48px;
  }
  .ft-sz-50 {
    font-size: 40px;
  }
  .ft-sz-40 {
    font-size: 32px;
  }
  .ft-sz-30 {
    font-size: 24px;
  }
  .ft-sz-20 {
    font-size: 16px;
  }
  .ft-sz-15 {
    font-size: 12px;
  }
  .ft-sz-12 {
    font-size: 10px;
  }
  .ft-sz-10 {
    font-size: 8px;
  }
  .ft-sz-9 {
    font-size: 7px;
  }
  .pad-tb-36 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #header {
    height: 80px;
  }
  #content {
    padding-top: 80px;
  }

  /*    .ft-sz-50 {
          font-size: 30px;
      }
      .ft-sz-30 {
          font-size: 18px;
      }
      .ft-sz-20 {
          font-size: 30px;
      }
      small, figcaption {
          font-size: 9px;
      }*/
}

@media screen and (min-width: 992px) {
  /* === FONTS === */
  .ft-sz-75{
    font-size:75px;
  }
  .ft-sz-60 {
    font-size: 60px;
  }
  .ft-sz-50 {
    font-size: 50px;
  }
  .ft-sz-40 {
    font-size: 40px;
  }
  .ft-sz-30 {
    font-size: 30px;
  }
  .ft-sz-20 {
    font-size: 20px;
  }
  .ft-sz-15 {
    font-size: 15px;
  }
  .ft-sz-12 {
    font-size: 12px;
  }
  .ft-sz-10 {
    font-size: 10px;
  }
  .ft-sz-9 {
    font-size: 9px;
  }

  .pad-tb-36 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /*#content {
      padding-bottom: 150px;
  }
  #footer {
      height: 150px;
  }*/
}
@media screen and (min-width: 1200px) {
  /* === FONTS === */
  .ft-sz-75{
    /*font-size:75px;*/
  }
  .ft-sz-60 {
    font-size: 90px;
  }
  .ft-sz-50 {
    font-size: 75px;
  }
  .ft-sz-40 {
    /*font-size: 68px;*/
  }
  .ft-sz-30 {
    font-size: 45px;
  }
  .ft-sz-20 {
    font-size: 30px;
  }
  .ft-sz-15 {
    font-size: 23px;
  }
  .ft-sz-12 {
    font-size: 18px;
  }
  .ft-sz-10 {
    /*font-size: 10px;*/
  }
  .ft-sz-9 {
    font-size: 14px;
  }

  /*#content {
      padding-bottom: 150px;
  }
  #footer {
      height: 150px;
  }*/
}
@media screen and (min-width: 1600px) {
  /* === FONTS === */
  .ft-sz-75{
    /*font-size:75px;*/
  }
  .ft-sz-60 {
    font-size: 120px;
  }
  .ft-sz-50 {
    font-size: 100px;
  }
  .ft-sz-40 {
    /*font-size: 68px;*/
  }
  .ft-sz-30 {
    font-size: 60px;
  }
  .ft-sz-20 {
    font-size: 40px;
  }
  .ft-sz-15 {
    font-size: 30px;
  }
  .ft-sz-12 {
    font-size: 24px;
  }
  .ft-sz-10 {
    /*font-size: 10px;*/
  }
  .ft-sz-9 {
    font-size: 18px;
  }

  /*#content {
      padding-bottom: 150px;
  }
  #footer {
      height: 150px;
  }*/

  .contact .ft-sz-12{
    font-size: 23px !important;
  }
  .content_marker span:last-of-type {
    width: 75%;
  }
}
